import React, { useContext } from "react";
import Heading from "../../Meta/Heading";
import Multiselect from "multiselect-react-dropdown";
import { Form } from "react-bootstrap";
import { BgColorContext } from "../../../Helpers/BgColor";

function Colors() {
  const { changeBgColor } = useContext(BgColorContext);

  let getBgColorOptions = [
    {
      label: "Blue",
      value: "primary",
    },
    {
      label: "Grey",
      value: "secondary",
    },
    {
      label: "Green",
      value: "success",
    },
    {
      label: "Yellow",
      value: "warning",
    },
    {
      label: "Red",
      value: "danger",
    },
    {
      label: "Light Blue",
      value: "info",
    },
    {
      label: "White",
      value: "light",
    },
    {
      label: "Black",
      value: "dark",
    },
  ];


  return (
    <div className="px-5 py-3">
      <Heading content="Background Colors" design="h5 mb-3" />
      <Form.Group>
        <Form.Label className="required">Choose Background Color</Form.Label>
        <Multiselect
          className="bg-white"
          displayValue="label"
          avoidHighlightFirstOption
          onKeyPressFn={function noRefCheck() {}}
          onSearch={function noRefCheck() {}}
          onSelect={changeBgColor}
          selectedValues={[JSON.parse(localStorage.getItem("bgColorTheme"))]}
          options={getBgColorOptions}
          singleSelect
          placeholder="Select Background Color"
        />
      </Form.Group>
    </div>
  );
}

export default Colors;
