import React, { useContext } from "react";
import { BgColorContext } from "./Helpers/BgColor";
import menu from "./Config/menu";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";

import { ToastContainer } from "react-toastify";
import { Row } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";

import Header from "./Components/Meta/Header";

function App() {
  const { bgColorTheme } = useContext(BgColorContext);

  return (
    <div className={`App min-vh-100 p-2 ${bgColorTheme}`}>
      <Row className="mb-5"></Row>
      <Row className="p-3">
        <Header />
        <Router>
          <Routes>
            {menu.map(({ path, name, element }, key) => {
              return (
                <Route
                  key={element}
                  exact
                  path={path}
                  name={name}
                  element={element}
                />
              );
            })}
          </Routes>
        </Router>
      </Row>
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default App;
