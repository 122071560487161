import React from "react";
import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";
import {
  BiMessageRoundedAdd,
  BiSend,
  BiMessageDetail,
  BiMailSend,
} from "react-icons/bi";

function Header() {
  return (
    <Navbar bg="light" expand="md" fixed="top">
      <Container>
        <Navbar.Brand href="/">VS</Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav className="me-auto my-2 my-lg-0" navbarScroll>
            <NavDropdown id="navbarScroll" title="Message" size="sm">
              <NavDropdown.Item href="/message">
                <BiMessageRoundedAdd /> Create Template
              </NavDropdown.Item>
              <NavDropdown.Item href="/messageSend">
                <BiSend /> Send Message
              </NavDropdown.Item>
              <NavDropdown.Item href="/messageEvent">
                <BiMessageDetail /> Event List
              </NavDropdown.Item>
              <NavDropdown.Item href="/messageLog">
                <BiMailSend /> Logs
              </NavDropdown.Item>
            </NavDropdown>

            <NavDropdown id="navbarScroll" title="Settings" size="sm">
              <NavDropdown.Item href="/settings">
                <BiMessageRoundedAdd /> Settings
              </NavDropdown.Item>
            </NavDropdown>

            <NavDropdown id="navbarScroll" title="Learn React" size="sm">
              <NavDropdown.Item href="/lesson1">
                <BiMessageRoundedAdd /> Lesson 1
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
          <Nav className="d-flex justify-content-center flex-column text-end mx-1 my-0">
            <p className="m-0 align-self-center">VS</p>
            <p className="m-0 fs-7 text-muted align-self-center">
              One Stop (HQ)
            </p>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
