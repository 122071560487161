import React, { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import Heading from "../Components/Meta/Heading";
import Colors from "../Components/Settings/Background/Colors";

function Settings() {
  const [key, setKey] = useState('home');
  return (
    <div className="p-3">
      <Heading content="Settings" design="h2" />
      <Tabs
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="my-3"
      >
        <Tab eventKey="profile" title="My Profile">
          <Heading content="My Profile" design="h2" />
        </Tab>
        <Tab eventKey="background" title="Background">
          <Colors />
        </Tab>
        <Tab eventKey="password" title="Password" >
          <Heading content="Password" design="h2" />
        </Tab>
      </Tabs>
    </div>
  );
}

export default Settings;
