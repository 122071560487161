import React, { useState, createContext, useEffect } from "react";

export const BgColorContext = createContext();

export const BgColorContextProvider = ({ children }) => {
  const [isBgColor, setIsBgColor] = useState();

  useEffect(() => {
    if (localStorage.getItem("bgColorTheme") === null) {
      const defaultColor = { label: "Green", value: "success" };
      localStorage.setItem("bgColorTheme", JSON.stringify(defaultColor));
    } else {
      setIsBgColor(JSON.parse(localStorage.getItem("bgColorTheme")).value);
    }
  }, [isBgColor]);

  const changeBgColor = (bgColor) => {
    localStorage.setItem("bgColorTheme", JSON.stringify(bgColor[0]));
    setIsBgColor(JSON.parse(localStorage.getItem("bgColorTheme")).value);
    // sessionStorage.setItem("bgtheme", value.target.checked);
  };

  const bgColorTheme = `bg-${isBgColor} animation bg-opacity-10`;

  return (
    <BgColorContext.Provider value={{ bgColorTheme, changeBgColor }}>
      {children}
    </BgColorContext.Provider>
  );
};
