import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { BgColorContextProvider } from "./Helpers/BgColor";

createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <BgColorContextProvider>
      <App />
    </BgColorContextProvider>
  </React.StrictMode>
);
